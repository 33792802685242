<template>
  <div class="inner-section">
    <bread-cumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('externalTradeFair.fair_participation_payment') + ' ' + ($route.query.id ? $t('globalTrans.update') : $t('globalTrans.entry')) }}</h4>
      </template>
      <template v-slot:body>
          <b-overlay :show="!dataLoaded">
            <b-row>
                <b-col sm="12" v-if="!dataLoaded" style="min-height: 400px"></b-col>
                <b-col sm="12" v-else>
                    <form-wizard class="fair-tab-wrapper" shape="circle" color="#005B5B" title="" subtitle="" :start-index.sync="activeIndex">
                        <tab-content :title="$t('externalTradeFair.applicant_info')" icon="ri-home-4-line" :before-change="tab1Submit" >
                            <Tab1
                                :app_id="app_id"
                                :application="application"
                                :app_address="address"
                                :circular="circular"
                                @countryType="setCountryType"
                                ref="tab_1_form"/>
                        </tab-content>
                        <tab-content :title="$t('externalTradeFair.org_owner_info')" icon="ri-list-settings-line" :before-change="tab2Submit">
                            <Tab2 :app_id="app_id" :officers="officer_owners" ref="tab_2_form"/>
                        </tab-content>
                        <tab-content v-if="application.circular_type == 1" :title="$t('externalTradeFair.business_type_and_product')" icon="ri-list-settings-line" :before-change="tab3Submit">
                            <Tab3 :app_id="app_id" :business_ids="business_type_ids" :products="products" ref="tab_3_form"/>
                        </tab-content>
                        <tab-content :title="$t('tradeFairConfig.stall_information')" icon="ri-list-settings-line" :before-change="tab4Submit">
                            <Tab4
                            :app_id="app_id"
                            :stallList="stallCategoryList"
                            :circular_type="application.circular_type"
                            :country_type="address.country_type"
                            :stalls="stalls" ref="tab_4_form"/>
                        </tab-content>

                        <tab-content :title="$t('externalUser.attachment')" icon="ri-list-settings-line" :before-change="tabLastSubmit">
                            <TabLast
                            :app_id="app_id"
                            :attachments="attachments"
                            :app_status="app_status"
                            :circular_type="application.circular_type"
                            :baseUrl="baseUrl" ref="tab_last_form"/>
                        </tab-content>
                        <template slot="footer" slot-scope="props">
                            <div class="wizard-footer-right">
                                <wizard-button class="btn btn-secondary rounded-pill" @click.native="props.prevTab(), step--"  v-if="props.activeTabIndex > 0" :style="props.fillButtonStyle">{{ $t('globalTrans.back') }}</wizard-button>
                                <wizard-button
                                    type="submit"
                                    class="btn btn-warning ml-1 mr-1 rounded-pill"
                                    title="Create Draft and Save!"
                                    @click.native="props.nextTab(), draft = 1"
                                >
                                    {{$t('globalTrans.save_draft')}}
                                </wizard-button>
                                <wizard-button
                                    type="submit"
                                    v-if="!props.isLastStep"
                                    @click.native="props.nextTab(), isNext = true"
                                    class="btn btn-success ml-1 mr-1 rounded-pill"
                                    title="Draft and go to next!"
                                    :style="props.fillButtonStyle"
                                >{{ $t('globalTrans.draft_n_next') }}
                                </wizard-button>
                                <wizard-button
                                    v-else
                                    type="submit"
                                    class="btn btn-success ml-1 rounded-pill"
                                    title="Submit!"
                                    @click.native="finalSave(), app_status = 2"
                                >
                                    {{ $t('globalTrans.finalSave')}}
                                </wizard-button>
                            </div>
                        </template>
                    </form-wizard>
                </b-col>
            </b-row>
          </b-overlay>
      </template>
    </body-card>
  </div>
</template>
<script>

import Tab1 from './Tab1'
import Tab2 from './Tab2'
import Tab3 from './Tab3'
import Tab4 from './Tab4'
import TabLast from './TabLast'

// import NoImage from '@/assets/images/user/no-image.jpg'
import BreadCumb from '@/components/BreadCumb.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import WizardButton from 'vue-form-wizard/src/components/WizardButton.vue'
import { mapGetters } from 'vuex'
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { ditfCircularDetailsStoreApi, DitfApplicationDetails } from '../../../api/routes'

export default {
  components: {
    BreadCumb, FormWizard, TabContent, WizardButton, Tab1, Tab2, Tab3, Tab4, TabLast
  },
  name: 'Form',
  props: [],
  data () {
    return {
        baseUrl: internationalTradeFairServiceBaseUrl,
        valid: null,
        errors: [],
        step: 0,
        activeIndex: 0,
        stallCategoryList: [],
        circular: {},
        application: {
            circular_id: 0,
            year: 0,
            circular_type: 0,
            fair_id: 0,
            name_en: '',
            name_bn: '',
            designation_en: '',
            designation_bn: '',
            comp_name_en: '',
            comp_name_bn: '',
            phone_no: '',
            mobile_no: '',
            email: '',
            fax: ''
        },
        address: {
            country_type: 1,
            country_id: 0,
            state_en: '',
            state_bn: '',
            city_en: '',
            city_bn: '',
            area_type_id: 0,
            division_id: 0,
            district_id: 0,
            city_corporation_id: 0,
            paurashava_id: 0,
            upazila_id: 0,
            union_id: 0,
            ward_id: 0,
            post_code: null,
            address_details_en: '',
            address_details_bn: ''
        },
        officer_owners: [],
        business_type_ids: [],
        products: [],
        stalls: [],
        attachments: {
            applicant_photo: '',
            trade_license: '',
            vat_regi_certificate: '',
            export_regi_certificate: '',
            prc_certificate: '',
            tax_certificate: '',
            chamber_certificate: '',
            bank_certificate: '',
            nid_attachment: '',
            signature: '',
            license_issue: 0
        },
        app_id: 0,
        app_status: 1,
        draft: 0,
        dataLoaded: false,
        country_type: 0
    }
  },
  created () {
    window.scrollTo(0, 0)
    if (this.$route.query.id) {
        this.app_id = this.$route.query.id
        this.getParticipateData()
    }
    if (this.$route.query.circular_id) {
        const circularId = this.$route.query.circular_id
        this.application.circular_id = circularId
        this.getCircularInfoById(circularId)
        // this.dataLoaded = true
    }
  },
  computed: {
    noteList: function () {
        return this.$store.state.ExternalUserService.tradeFair.commonObj.noteList
    },
    loading: function () {
        return this.$store.state.commonObj.loading
    },
    ...mapGetters({
        commonProfile: 'Auth/commonProfile',
        authUser: 'Auth/authUser'
    })
  },
  methods: {
    finalSave () {
        this.$swal({
            title: this.$t('globalTrans.final_save_msg'),
            showCancelButton: true,
            confirmButtonText: this.$t('globalTrans.yes'),
            cancelButtonText: this.$t('globalTrans.no'),
            focusConfirm: false
        }).then((result) => {
            if (result.isConfirmed) {
                this.tabLastSubmit()
            } else {
                this.app_status = 1
            }
        })
    },
    setCountryType (type) {
        this.country_type = type
    },
    async getParticipateData () {
        const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, DitfApplicationDetails, { app_id: this.app_id })
        if (result.success) {
            const data = result.data
            if (data) {
                this.getCircularInfoById(data.circular_id)
                // tab - 1
                this.application = {
                    circular_id: data.circular_id,
                    year: data.year,
                    circular_type: data.circular_type,
                    fair_id: data.fair_id,
                    name_en: data.name_en,
                    name_bn: data.name_bn,
                    designation_en: data.designation_en,
                    designation_bn: data.designation_bn,
                    comp_name_en: data.comp_name_en,
                    comp_name_bn: data.comp_name_bn,
                    phone_no: data.phone_no,
                    mobile_no: data.mobile_no,
                    email: data.email,
                    fax: data.fax
                }
                if (data.address) {
                    this.address = data.address
                }
                this.activeIndex = 1
                // tab - 2
                if (data.owner_officer.length) {
                    this.officer_owners = data.owner_officer
                    this.activeIndex = 2
                }
                // tab - 3
                if (data.disp_products.length && data.business_type.length) {
                    this.business_type_ids = data.business_type.map(el => {
                        return el.busin_type_id
                    })
                    this.products = data.disp_products
                    this.activeIndex = 3
                }
                // tab - 4
                if (data.circular_type === 1 && data.stall_pavilion.length) {
                    this.stalls = data.stall_pavilion
                    this.activeIndex = 4
                }
                if (data.circular_type === 2 && data.stall_other.length) {
                    this.stalls = data.stall_other
                    this.activeIndex = 3
                }
                if (data.attachments) {
                    this.attachments = data.attachments
                }
            }
            // this.dataLoaded = true
        } else {
            this.dataLoaded = false
        }
    },
    async getCircularInfoById (circularId) {
        const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, ditfCircularDetailsStoreApi, { circular_id: circularId })
        if (result.success) {
            const fData = result.data
            if (fData) {
                this.circular = fData
                // this.application.circular_id = this.circular.circular_id
                this.application.fair_id = this.circular.fair_id
                this.application.circular_type = this.circular.circular_type
                this.application.year = this.circular.year
                // this.stallCategoryList = this.getStallCategoryList()
                this.stallCategoryList = result.stallList
            }
            this.dataLoaded = true
        } else {
            this.$router.push({ path: 'circular-list' })
        }
    },
    getStallCategoryList () {
        return this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.filter(el => el.circular_type === parseInt(this.circular.circular_type) && el.year === parseInt(this.circular.year) && el.status === 1)
    },
    async tabLastSubmit () {
        // this.$router.push({ path: 'fair-application' })
        const result = await this.$refs.tab_last_form.submit()
        // if (result === undefined) {
        //     this.app_status = 1
        // }
        if (result.success) {
            this.$router.push({ path: 'fair-application' })
        }
    },
    async tab4Submit () {
        // return true
        const result = await this.$refs.tab_4_form.submit()
        if (result.success) {
            if (this.draft) {
                this.$router.push({ path: 'fair-application' })
            } else {
                return true
            }
        }
    },
    async tab3Submit () {
        const result = await this.$refs.tab_3_form.submit()
        if (result.success) {
            if (this.draft) {
                this.$router.push({ path: 'fair-application' })
            } else {
                return true
            }
        }
    },
    async tab2Submit () {
        // return true
        const result = await this.$refs.tab_2_form.submit()
        if (result.success) {
            if (this.draft) {
                this.$router.push({ path: 'fair-application' })
            } else {
                return true
            }
        }
    },
    async tab1Submit () {
        // return true
        const result = await this.$refs.tab_1_form.submit()
        if (result.success) {
            if (this.draft) {
                this.$router.push({ path: 'fair-application' })
            } else {
                this.app_id = result.data.id
                this.$refs.tab_4_form.filterStallList(this.country_type)
                return true
            }
        }
    }
  }
}
</script>
<style>
.fair-note-wrapper {
    margin-bottom: 20px;
}
.fair-note-wrapper p {
    margin-bottom: 10px;
}
.fair-note-wrapper .note-title {
    background: #ffb01e;
    margin-right: 2px;
    padding: 2px 5px;
    font-weight: 600;
    border-radius: 2px;
}
.fair-tab-wrapper.vue-form-wizard.md .wizard-navigation .wizard-nav-pills li {
    padding: 5px 0 20px;
}
</style>
