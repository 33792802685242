<template>
    <ValidationObserver ref="form2"  v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset">
            <b-overlay :show="unitLoad">
                <div class="application-form-wrapper">
                    <div class="application-itmes">
                        <b-row>
                            <b-col sm="12">
                                <div class="group-form-card">
                                    <b-card>
                                        <b-form-group label-cols-lg="3"
                                            :label="$t('externalTradeFair.product_displayed')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                            <b-row>
                                                <b-col sm="6">
                                                    <ValidationProvider name="Product Name (En)" vid="prod_name_en" rules="required" v-slot="{ errors }">
                                                        <b-form-group label-for="prod_name_en" label-cols-sm="5">
                                                            <template v-slot:label>
                                                                {{ $t('externalTradeFair.prod_name') + ' ' + $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input v-model="detail_obj.prod_name_en"
                                                                :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col sm="6">
                                                    <ValidationProvider name="Product Name (Bn)" vid="prod_name_bn" v-slot="{ errors }">
                                                        <b-form-group label-for="prod_name_bn" label-cols-sm="5">
                                                            <template v-slot:label>
                                                                {{ $t('externalTradeFair.prod_name') + ' ' + $t('globalTrans.bn') }}
                                                            </template>
                                                            <b-form-input v-model="detail_obj.prod_name_bn"
                                                                :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col>
                                                    <div class="text-right">
                                                        <b-button type="submit" size="sm" variant="primary" class="mr-1"><i class="ri-add-fill" style="vertical-align: bottom;"></i> {{ editIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add')}}</b-button>
                                                        <b-button type="button" v-if="editIndex !== ''" size="sm" variant="danger" @click="cancelItem()"><i class="ri-close-fill" style="vertical-align: bottom;"></i> {{ $t('globalTrans.cancel') }}</b-button>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            <hr>
                                            <b-row>
                                                <b-col xl="12" lg="12" sm="12">
                                                    <b-table-simple bordered>
                                                        <thead class="text-white bg-primary">
                                                            <tr>
                                                                <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                                                <th scope="col">{{ $t('externalTradeFair.prod_name') }}</th>
                                                                <th width="15%" class="text-center">{{ $t('globalTrans.action') }}</th>
                                                            </tr>
                                                        </thead>
                                                        <b-tbody>
                                                            <b-tr v-for="(item, index) in products" :key="index">
                                                                <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                                                <b-td>{{ $i18n.locale === 'en' ? item.prod_name_en : item.prod_name_bn }}</b-td>
                                                                <b-td class="text-center">
                                                                    <b-button @click="editItem(item, index)" title="Edit" class="btn btn-success btn-sm mr-1"><i class="ri-pencil-fill m-0"></i></b-button>
                                                                    <b-button type="button" @click="deleteItem(index)" title="Delete" class="btn btn-danger" size="sm"><i class="ri-delete-bin-2-line"></i></b-button>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr v-if="products.length <= 0">
                                                                <td colspan="3" class="text-center">
                                                                    <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                                                                </td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                            </b-row>
                                        </b-form-group>
                                    </b-card>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </b-overlay>
        </b-form>
    </ValidationObserver>
</template>

<script>
export default {
    props: ['products'],
    data () {
      return {
        valid: null,
        unitLoad: false,
        editIndex: '',
        detail_obj: {
            prod_name_en: '',
            prod_name_bn: ''
        }
      }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    created () {
    },
    methods: {
        cancelItem () {
            this.detail_obj = {}
            this.editIndex = ''
            this.$refs.form2.reset()
        },
        editItem (item, index) {
            this.detail_obj = Object.assign({}, item)
            this.editIndex = index
        },
        deleteItem (index) {
            this.products.splice(index, 1)
        },
        async addItem () {
            let result = []
            if (this.editIndex !== '') {
                const editList = [...this.products]
                editList.splice(parseInt(this.editIndex), 1)
                result = [...editList]
            } else {
                result = this.products
            }
            const Product = this.detail_obj.prod_name_en
            const newData = result.find(item => item.prod_name_en === Product)
            if (typeof newData === 'undefined') {
                    if (this.editIndex !== '') {
                        this.products[parseInt(this.editIndex)] = this.detail_obj
                    } else {
                        this.products.push(JSON.parse(JSON.stringify(this.detail_obj)))
                    }
                    this.detail_obj = {
                        prod_name_en: '',
                        prod_name_bn: ''
                    }
                    this.editIndex = ''
            } else {
                this.$toast.error({
                    title: '!',
                    message: 'Item already added',
                    color: '#ee5253'
                })
            }
            this.$nextTick(() => {
                this.$refs.form2.reset()
            })
        }
    }
}
</script>
