<template>
    <b-row>
        <b-col md="12">
            <!-- <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset"> -->
                    <b-overlay :show="unitLoad">
                        <!-- <b-col lg="12" md="12" sm="12"> -->
                            <add-owner-officers :items="officers" :type="2" title="Offiers Inforamtion"/>
                            <add-owner-officers :items="officers" :type="1" title="Owner Inforamtion"/>
                        <!-- </b-col> -->
                    </b-overlay>
                <!-- </b-form>
            </ValidationObserver> -->
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { ditfTabTwoStoreApi } from '../../../api/routes'
import AddOwnerOfficers from './AddOwnerOfficers'

export default {
    props: ['app_id', 'officers'],
    components: { AddOwnerOfficers },
    data () {
        return {
            errors: [],
            valid: null,
            unitLoad: false
        }
    },
    created () {
    },
    watch: {
        currentLocale: function (newVal, oldVal) {
            if (newVal !== oldVal) {
            }
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        async submit () {
            if (!this.officers.filter(el => el.info_type === 2).length) {
                return this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: 'Add at least one nominated officer information!',
                    color: 'red'
                })
            }
            if (!this.officers.filter(el => el.info_type === 1).length) {
                return this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: 'Add at least one owner information!',
                    color: 'red'
                })
            }
            this.unitLoad = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const loadingState = { loading: false, listReload: false }

            const myObj = {
                app_id: this.app_id,
                owner_officer: this.officers
            }
            const result = await RestApi.postData(internationalTradeFairServiceBaseUrl, ditfTabTwoStoreApi, myObj)
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.unitLoad = false
            if (result.success) {
                this.$toast.success({
                    title: 'Success',
                    message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                return result
            } else {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: this.$t('globalTrans.form_error_msg'),
                    color: '#ee5253'
                })
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
