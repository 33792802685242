<template>
  <b-row>
    <b-col class="mb-3">
        <div class="bg-dark w-50 m-auto rounded-pill">
            <h6 class="text-white text-center py-1">{{ $t('ditfConfig.stall_list') }}</h6>
        </div>
    </b-col>
    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
      <ValidationObserver ref="form3" v-slot="{ handleSubmit, reset }">
          <b-form id="form" @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <div class="text-right">
                        <span class="font-weight-bold">{{ $t('externalTradeFair.floor_price') + ': ' }}</span>
                        <span>{{ $n(item.floor_price, { minimumFractionDigits: 2 }) + ' ' + $t('globalTrans.tk') }}</span>
                    </div>
                    <!-- <div class="text-center"><span>{{ $t('externalTradeFair.stall_selection_note') }}</span></div> -->
                    <b-table-simple class="mb-0" bordered hover small>
                        <thead>
                            <tr class="text-align-center text-center">
                                <th v-if="entryMode" style="width:5%">{{ $t('globalTrans.select') }}</th>
                                <th style="width:15%">{{ $t('ditfConfig.stall_no') }}</th>
                                <th style="width:25%">{{ $t('ditfConfig.stall_location') }}</th>
                                <th style="width:25%">{{ $t('externalTradeFair.quoted_price') + ' ' + $t('globalTrans.tk') }}</th>
                            </tr>
                        </thead>
                        <b-tbody>
                            <b-tr v-for="(element, index) in stalls" :key="index">
                                <b-td v-if="entryMode" class="text-center">
                                    <div>
                                        <b-form-checkbox @change="setQuotedPrice(element, index)" :id="'checkbox-2'+index" v-model="element.checked" :name="'checkbox-2'+index" :value=1
                                            :unchecked-value=0>
                                        </b-form-checkbox>
                                    </div>
                                </b-td>
                                <b-td class="text-center">{{ ($i18n.locale == 'en' ? 'Stall-' : 'স্টল-') + $n(element.stall_no) }}</b-td>
                                <b-td class="text-center">{{ $i18n.locale == 'en' ? element.stall_location_en : element.stall_location_bn }}</b-td>
                                <b-td v-if="element.checked && entryMode">
                                    <div v-if="item.price_type == 2">
                                        <ValidationProvider name="Quoted Price" :vid="'quoted_price'+index" :rules="{min_value: item.floor_price, required: true, regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                                            <b-form-group class="mb-0" label-for="quoted_price">
                                                <b-form-input
                                                v-model="element.quoted_price"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="text-right" v-else>
                                        <span v-if="element.checked">{{ $n(item.floor_price, { minimumFractionDigits: 2 }) }}</span>
                                        <span v-else>{{ $n(0) }}</span>
                                    </div>
                                </b-td>
                                <b-td class="text-right" v-else>
                                    <span v-if="item.price_type == 2">{{ element?.quoted_price ? $n(element?.quoted_price, { minimumFractionDigits: 2 }) : $n(0) }}</span>
                                    <span v-else>
                                        <span v-if="element.checked">{{ $n(item.floor_price, { minimumFractionDigits: 2 }) }}</span>
                                        <span v-else>{{ $n(0) }}</span>
                                    </span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="stalls.length <= 0">
                                <td :colspan="entryMode ? 4 : 3" class="text-center">
                                    <span class="text-black">{{ ($i18n.locale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                                </td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <b-row class="text-right">
              <b-col class="mt-2 text-right">
                  <b-button v-if="entryMode" type="button" @click="saveUpdate" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                  <b-button variant="danger" class="btn-sm" @click="cancel()">{{ $t('globalTrans.cancel') }}</b-button>
              </b-col>
            </b-row>
          </b-form>
      </ValidationObserver>
    </b-col>
  </b-row>
</template>
<script>

export default {
  name: 'StallModal',
  props: ['item', 'entryMode'],
  components: {
  },
  data () {
    return {
      valid: null,
      saveBtnName: this.$t('globalTrans.save'),
      errors: [],
      stalls: []
    }
  },
  created () {
    if (this.item.id) {
        const stalls = JSON.parse(JSON.stringify(this.item.stall_numbers))
        if (this.$route.query.circular_id) {
            this.stalls = stalls.map(el => {
                if (this.entryMode) {
                    return Object.assign({}, el, { quoted_price: this.item.price_type === 1 ? this.item.floor_price : el.quoted_price })
                }
                return Object.assign({}, el, { quoted_price: this.item.price_type === 1 ? this.item.floor_price : '' })
            })
        } else {
            this.stalls = stalls
        }
    }
  },
  computed: {
  },
  methods: {
    setQuotedPrice (item, index) {
        if (!item.checked) {
            this.stalls[index].quoted_price = 0
        }
    },
    async saveUpdate () {
        var check = await this.$refs.form3.validate()
        if (check) {
            this.item.stall_numbers = [...this.stalls]
            const isChecked = this.stalls.find(el => el.checked === 1)
            if (typeof isChecked !== 'undefined') {
                this.item.is_active = 1
                this.item.stall_assigned = 1
            } else {
                return this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: 'Select at least one item!',
                    color: 'red'
                })
            }
            this.$emit('setStallItem')
            this.$bvModal.hide('modal-stall')
        }
    },
    cancel () {
        const isChecked = this.item.stall_numbers.find(el => el?.checked === 1)
        if (typeof isChecked === 'undefined') {
            this.item.is_active = 0
        } else {
            this.item.is_active = 1
        }
        this.$emit('setStallItem')
        this.$bvModal.hide('modal-stall')
    }
  }
}
</script>
