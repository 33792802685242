<template>
    <ValidationObserver ref="form2"  v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset">
            <b-overlay :show="unitLoad">
                <div class="application-form-wrapper">
                    <div class="application-itmes">
                        <b-row>
                            <b-col sm="12">
                                <div class="group-form-card">
                                    <b-card>
                                        <b-form-group label-cols-lg="3"
                                            :label="type == 1 ? $t('externalTradeFair.org_owner_info') : $t('externalTradeFair.org_officer_info')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                            <b-row>
                                                <b-col sm="6">
                                                    <ValidationProvider name="Name (En)" vid="name_en" rules="required" v-slot="{ errors }">
                                                        <b-form-group label-for="name_en" label-cols-sm="5">
                                                            <template v-slot:label>
                                                                {{ $t('globalTrans.name') + ' ' + $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input v-model="detail_obj.name_en"
                                                                :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col sm="6">
                                                    <ValidationProvider name="Name (Bn)" vid="name_bn" v-slot="{ errors }">
                                                        <b-form-group label-for="name_bn" label-cols-sm="5">
                                                            <template v-slot:label>
                                                                {{ $t('globalTrans.name') + ' ' + $t('globalTrans.bn') }}
                                                            </template>
                                                            <b-form-input v-model="detail_obj.name_bn"
                                                                :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col sm="6">
                                                    <ValidationProvider name="Designation (En)" vid="designation_en" rules="required" v-slot="{ errors }">
                                                        <b-form-group label-for="designation_en" label-cols-sm="5">
                                                            <template v-slot:label>
                                                                {{ $t('globalTrans.designation') + ' ' + $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input v-model="detail_obj.designation_en"
                                                                :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col sm="6">
                                                    <ValidationProvider name="Designation (Bn)" vid="designation_bn" v-slot="{ errors }">
                                                        <b-form-group label-for="designation_bn" label-cols-sm="5">
                                                            <template v-slot:label>
                                                                {{ $t('globalTrans.designation') + ' ' + $t('globalTrans.bn') }}
                                                            </template>
                                                            <b-form-input v-model="detail_obj.designation_bn"
                                                                :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col sm="6">
                                                    <ValidationProvider name="Address (En)" vid="address_en" rules="required" v-slot="{ errors }">
                                                        <b-form-group label-for="address_en" label-cols-sm="5">
                                                            <template v-slot:label>
                                                                {{ $t('globalTrans.address') + ' ' + $t('globalTrans.en')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input v-model="detail_obj.address_en"
                                                                :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col sm="6">
                                                    <ValidationProvider name="Address (Bn)" vid="address_bn" v-slot="{ errors }">
                                                        <b-form-group label-for="address_bn" label-cols-sm="5">
                                                            <template v-slot:label>
                                                                {{ $t('globalTrans.address') + ' ' + $t('globalTrans.bn')}}
                                                            </template>
                                                            <b-form-input v-model="detail_obj.address_bn"
                                                                :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col sm="6">
                                                    <ValidationProvider name="Telephone No." vid="phone_no" rules="max:11" v-slot="{ errors }">
                                                        <b-form-group label-for="phone_no" label-cols-sm="5">
                                                            <template v-slot:label>
                                                                {{ $t('externalUser.telephone_no') }}
                                                            </template>
                                                            <b-form-input v-model="detail_obj.phone_no" :state="errors[0] ? false : (valid ? true : null)"
                                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col sm="6">
                                                    <ValidationProvider name="Mobile No." vid="mobile_no" rules="required|max:11" v-slot="{ errors }">
                                                        <b-form-group label-for="mobile_no" label-cols-sm="5">
                                                            <template v-slot:label>
                                                                {{ $t('globalTrans.mobile_no') }} <span
                                                                    class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input v-model="detail_obj.mobile_no" :state="errors[0] ? false : (valid ? true : null)"
                                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col>
                                                    <div class="text-right">
                                                        <b-button type="submit" size="sm" variant="primary" class="mr-1"><i class="ri-add-fill" style="vertical-align: bottom;"></i> {{ editIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add')}}</b-button>
                                                        <b-button type="button" v-if="editIndex !== ''" size="sm" variant="danger" @click="cancelItem()"><i class="ri-close-fill" style="vertical-align: bottom;"></i> {{ $t('globalTrans.cancel') }}</b-button>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            <hr>
                                            <b-row>
                                                <b-col xl="12" lg="12" sm="12">
                                                    <b-table-simple bordered>
                                                        <thead class="text-white bg-primary">
                                                            <tr>
                                                                <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                                                <th scope="col">{{ $t('globalTrans.name') }}</th>
                                                                <th scope="col">{{ $t('globalTrans.designation') }}</th>
                                                                <th scope="col">{{ $t('globalTrans.address') }}</th>
                                                                <th scope="col">{{ $t('externalUser.telephone_no') }}</th>
                                                                <th scope="col">{{ $t('globalTrans.mobile_no') }}</th>
                                                                <th width="15%" class="text-center">{{ $t('globalTrans.action') }}</th>
                                                            </tr>
                                                        </thead>
                                                        <b-tbody>
                                                            <b-tr v-for="(item, index) in items.filter(el => el.info_type === type)" :key="index">
                                                                <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                                                <b-td>{{ $i18n.locale === 'en' ? item.name_en : item.name_bn }}</b-td>
                                                                <b-td>{{ $i18n.locale === 'en' ? item.designation_en : item.designation_bn }}</b-td>
                                                                <b-td>{{ $i18n.locale === 'en' ? item.address_en : item.address_bn }}</b-td>
                                                                <b-td>{{ item.phone_no | mobileNumber }}</b-td>
                                                                <b-td>{{ item.mobile_no | mobileNumber }}</b-td>
                                                                <b-td class="text-center">
                                                                    <b-button @click="editItem(item, index)" title="Edit" class="btn btn-success btn-sm mr-1"><i class="ri-pencil-fill m-0"></i></b-button>
                                                                    <b-button type="button" @click="deleteItem(index)" title="Delete" class="btn btn-danger" size="sm"><i class="ri-delete-bin-2-line"></i></b-button>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr v-if="items.length <= 0">
                                                                <td colspan="7" class="text-center">
                                                                    <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                                                                </td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                            </b-row>
                                        </b-form-group>
                                    </b-card>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </b-overlay>
        </b-form>
    </ValidationObserver>
</template>

<script>
export default {
    props: ['items', 'type'],
    data () {
      return {
        valid: null,
        unitLoad: false,
        editIndex: '',
        detail_obj: {
            info_type: this.type,
            name_en: '',
            name_bn: '',
            designation_en: '',
            designation_bn: '',
            address_en: '',
            address_bn: '',
            mobile_no: '',
            phone_no: ''
        }
      }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    created () {
    },
    methods: {
        cancelItem () {
            this.detail_obj = {}
            this.editIndex = ''
            this.$refs.form2.reset()
        },
        editItem (item, index) {
            this.detail_obj = Object.assign({}, item)
            this.editIndex = index
        },
        deleteItem (index) {
            this.items.splice(index, 1)
        },
        async addItem () {
            let result = []
            if (this.editIndex !== '') {
                const editList = [...this.items]
                editList.splice(parseInt(this.editIndex), 1)
                result = [...editList]
            } else {
                result = this.items
            }
            // const Name = this.detail_obj.name_en
            // const Desig = this.detail_obj.designation_en
            const Mobile = this.detail_obj.mobile_no
            const newData = result.find(item => item.mobile_no === Mobile)
            if (typeof newData === 'undefined') {
                    if (this.editIndex !== '') {
                        this.items[parseInt(this.editIndex)] = this.detail_obj
                    } else {
                        this.items.push(JSON.parse(JSON.stringify(this.detail_obj)))
                    }
                    this.detail_obj = {
                        info_type: this.type,
                        name_en: '',
                        name_bn: '',
                        designation_en: '',
                        designation_bn: '',
                        address_en: '',
                        address_bn: '',
                        mobile_no: '',
                        phone_no: ''
                    }
                    this.editIndex = ''
            } else {
                this.$toast.error({
                    title: '!',
                    message: 'Item already added',
                    color: '#ee5253'
                })
            }
            this.$nextTick(() => {
                this.$refs.form2.reset()
            })
        }
    }
}
</script>
