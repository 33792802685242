<template>
    <b-row>
        <b-col md="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay :show="unitLoad">
                        <div class="application-form-wrapper">
                            <div class="application-itmes fair-tab2-wrapper">
                                  <div class="group-form-card">
                                      <b-card>
                                          <b-form-group label-cols-lg="12" :label="$t('externalTradeFair.choose_stall_category') + '' + $t('externalTradeFair.stall_selection_note')" label-size="lg"
                                              label-class="font-weight-bold pt-0" class="mb-0">
                                              <b-row>
                                                <!-- <b-col sm="12" class="text-center">{{ $t('externalTradeFair.stall_selection_note') }}"</b-col> -->
                                                  <b-col sm="12">
                                                      <b-table-simple class="mb-0" bordered hover small>
                                                          <thead>
                                                              <tr class="text-white text-align-center">
                                                                  <th style="width:5%">{{ $t('globalTrans.select') }}</th>
                                                                  <th style="width:12%">{{ $t('tradeFairConfig.stall_type') }}</th>
                                                                  <th style="width:25%">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                                                                  <th style="width:10%">{{ $t('ditfConfig.size') }}</th>
                                                                  <th style="width:15%">{{ $t('ditfConfig.app_porcess_fee') + ' ' + $t('globalTrans.tk') }}</th>
                                                                  <th style="width:15%">{{ $t('ditfConfig.fare_floor') + ' ' + $t('globalTrans.tk') }}</th>
                                                                  <th v-if="circular_type == 1" style="width:10%">{{ $t('globalTrans.action') }}</th>
                                                                  <th v-else style="width:15%">{{ $t('externalTradeFair.quoted_price') + ' ' + $t('globalTrans.tk') }}</th>
                                                              </tr>
                                                          </thead>
                                                          <b-tbody>
                                                              <b-tr v-for="(item, index) in stallCategoryList" :key="index">
                                                                  <b-td v-if="circular_type == 1" class="text-center">
                                                                      <b-form-checkbox @change="checkedAction(item, index)" :id="'checkbox-1'+index" v-model="item.is_active" :name="'checkbox-1'+index" :value=1
                                                                          :unchecked-value=0>
                                                                      </b-form-checkbox>
                                                                  </b-td>
                                                                  <b-td v-else class="text-center">
                                                                      <b-form-checkbox :id="'checkbox-1'+index" v-model="item.is_active" :name="'checkbox-1'+index" :value=1
                                                                          :unchecked-value=0>
                                                                      </b-form-checkbox>
                                                                  </b-td>
                                                                  <b-td>{{ $n(index + 1) }}. {{ stallTypeName(item.price_type) }}</b-td>
                                                                  <b-td>{{ currentLocale == 'en' ? item.cat_name_en : item.cat_name_bn }}</b-td>
                                                                  <b-td>{{ currentLocale == 'en' ? item.size_en : item.size_bn }}</b-td>
                                                                  <b-td>{{ $n(item.processing_fee, { minimumFractionDigits: 2 }) }}</b-td>
                                                                  <b-td>{{ $n(item.floor_price, { minimumFractionDigits: 2 }) }}</b-td>
                                                                  <b-td v-if="circular_type == 1" class="text-left">
                                                                        <b-button :title="$t('globalTrans.details')" v-b-modal.modal-stall variant=" action-btn status" size="sm" @click="detailsStall(item, index)"><i class="ri-eye-fill"></i></b-button>
                                                                        <b-button v-if="item.stall_assigned" :title="$t('globalTrans.edit')" v-b-modal.modal-stall variant=" action-btn edit" size="sm" @click="editAction(item, index)"><i class="ri-pencil-fill"></i></b-button>
                                                                  </b-td>
                                                                  <b-td v-else>
                                                                    <div v-if="item.price_type == 2">
                                                                        <ValidationProvider name="Quoted Price" :vid="'quoted_price'+index" :rules="{min_value: item.floor_price, required: item.is_active ? true : false, regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                                                                            <b-form-group class="mb-0" label-for="quoted_price">
                                                                                <b-form-input
                                                                                v-model="item.quoted_price"
                                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                                >
                                                                                </b-form-input>
                                                                                <div class="invalid-feedback">
                                                                                    {{ errors[0] }}
                                                                                </div>
                                                                            </b-form-group>
                                                                        </ValidationProvider>
                                                                    </div>
                                                                    <div v-else>{{ $n(item.quoted_price, { minimumFractionDigits: 2 }) }}</div>
                                                                  </b-td>
                                                              </b-tr>
                                                              <b-tr v-if="stallCategoryList.length <= 0">
                                                                  <td colspan="7" class="text-center">
                                                                      <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                                                                  </td>
                                                              </b-tr>
                                                          </b-tbody>
                                                      </b-table-simple>
                                                  </b-col>
                                              </b-row>
                                          </b-form-group>
                                      </b-card>
                                  </div>
                            </div>
                        </div>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
        <b-modal id="modal-stall" size="lg" hide-footer no-close-on-backdrop ok-only ok-variant="danger">
            <template #modal-header="{ close }">
                <div>{{ $t('ditfConfig.stall_info') }}</div>
                <b-button size="sm" variant="outline-danger" :title="$t('globalTrans.close')" class="bg-none text-white" style="border:none; display:none" @click="close()">
                    <i class="ri-close-line"></i>
                </b-button>
            </template>
            <stall-modal :item="aStall" :entryMode="entryMode" @setStallItem="resetStallItem" :key="aStall.id"/>
        </b-modal>
    </b-row>
</template>
<script>
import StallModal from './StallModal'
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { ditfTabFourStoreApi } from '../../../api/routes'

export default {
    props: ['app_id', 'stallList', 'circular_type', 'country_type', 'stalls'],
    components: { StallModal },
    data () {
        return {
            errors: [],
            valid: null,
            unitLoad: false,
            stallCategoryList: [],
            aStall: {},
            stIndex: 0,
            entryMode: false
        }
    },
    created () {
        if (this.$route.query.id) {
            this.stallCategoryList = this.stallList.map(el => {
                const exists = this.stalls.find(item => item.stall_cat_id === el.id)
                if (this.circular_type === 2) {
                    if (typeof exists !== 'undefined') {
                        return Object.assign({}, el, { is_active: 1, stall_assigned: 0, quoted_price: el.price_type === 1 ? el.floor_price : exists.quoted_price })
                    } else {
                        return Object.assign({}, el, { is_active: 0, stall_assigned: 0, quoted_price: el.price_type === 1 ? el.floor_price : '' })
                    }
                } else {
                    if (typeof exists !== 'undefined') {
                        const stallDetails = el.stall_numbers.map(newitm => {
                            const existStall = this.stalls.find(item => item.stall_id === newitm.id)
                            if (typeof existStall !== 'undefined') {
                                return Object.assign({}, newitm, { checked: 1, quoted_price: existStall.quoted_price })
                            } else {
                                return newitm
                            }
                        })
                        const newObj = {
                            is_active: 1,
                            stall_assigned: 1,
                            stall_numbers: stallDetails
                        }
                        return Object.assign({}, el, newObj)
                    } else {
                        return Object.assign({}, el, { is_active: 0, stall_assigned: 0 })
                    }
                }
            })
            this.filterStallList(this.country_type)
        } else {
            this.stallCategoryList = this.stallList.map(el => {
                if (this.circular_type === 2) {
                    return Object.assign({}, el, { is_active: 0, stall_assigned: 0, quoted_price: el.price_type === 1 ? el.floor_price : '' })
                } else {
                    return Object.assign({}, el, { is_active: 0, stall_assigned: 0 })
                }
            })
        }
    },
    watch: {
        currentLocale: function (newVal, oldVal) {
            if (newVal !== oldVal) {
            }
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        resetStallItem () {
            this.stallCategoryList[this.stIndex] = this.aStall
        },
        checkedAction (item, index) {
            if (!item.stall_assigned) {
                this.entryMode = true
                this.aStall = item
                this.stIndex = index
                this.$bvModal.show('modal-stall')
            }
        },
        editAction (item, index) {
            this.entryMode = true
            this.aStall = item
            this.stIndex = index
        },
        detailsStall (item, index) {
            this.entryMode = false
            this.aStall = item
            this.stIndex = index
        },
        stallTypeName (Id) {
            const Obj = this.$store.state.TradeFairService.ditfCommonObj.priceTypeList.find(item => item.value === Id)
            if (typeof Obj !== 'undefined') {
                return this.currentLocale === 'en' ? Obj.text_en : Obj.text_bn
            }
            return ''
        },
        filterStallList (countryType) {
            this.stallCategoryList = this.stallCategoryList.filter(el => el.stall_type === parseInt(countryType))
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                if (!this.stallCategoryList.filter(el => el.is_active === 1).length) {
                    return this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: 'Add at least one stall category item!',
                        color: 'red'
                    })
                }

                const myArr = []
                if (this.circular_type === 1) {
                    this.stallCategoryList.filter(item => item.is_active === 1).map(el => {
                        const stalls = el.stall_numbers.filter(itm => itm.checked === 1)
                        stalls.forEach(st => {
                            myArr.push({ stall_cat_id: el.id, stall_id: st.id, quoted_price: el.price_type === 2 ? st.quoted_price : el.floor_price })
                        })
                    })
                } else {
                    this.stallCategoryList.filter(item => item.is_active === 1).map(el => {
                        myArr.push({ stall_cat_id: el.id, quoted_price: el.price_type === 2 ? el.quoted_price : el.floor_price })
                    })
                }

                this.unitLoad = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }

                const myObj = {
                    app_id: this.app_id,
                    stalls: myArr
                }
                const result = await RestApi.postData(internationalTradeFairServiceBaseUrl, ditfTabFourStoreApi, myObj)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                this.unitLoad = false
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#ee5253'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            }
        }
    }
}
</script>
<style scoped>
.application-itmes.fair-tab2-wrapper .table {
    border-collapse: separate;
    border-spacing: 0 10px;
    border: none;
}
.application-itmes.fair-tab2-wrapper .table thead tr {
    background: #15273B;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.application-itmes.fair-tab2-wrapper .table tbody tr {
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
}
.application-itmes.fair-tab2-wrapper .table thead tr th,
.application-itmes.fair-tab2-wrapper .table tbody tr td {
    border: none;
}
</style>
