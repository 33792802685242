<template>
    <b-row>
        <b-col sm="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay :show="unitLoad">
                        <div class="application-form-wrapper">
                            <div class="application-itmes">
                                <b-row>
                                    <b-col sm="12">
                                        <div class="group-form-card">
                                            <b-card>
                                                <b-form-group label-cols-lg="3" :label="$t('ditfConfig.business_type') + ' ' + $t('globalTrans.information')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0">
                                                    <b-row>
                                                        <b-col xl="12" lg="12" sm="12">
                                                          <ValidationProvider name="Business Type" vid="customer_type_id" rules="required|min:1">
                                                            <b-form-group
                                                                label-for="customer_type_id"
                                                                slot-scope="{ valid, errors }"
                                                              >
                                                              <template v-slot:label>
                                                                {{ $t('ditfConfig.business_type') }} <span class="text-danger">*</span>
                                                              </template>
                                                                <b-form-checkbox-group
                                                                  v-model="type_ids"
                                                                  :options="businessTypeList"
                                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-checkbox-group>
                                                                <div class="invalid-feedback d-block">
                                                                {{ errors[0] }}
                                                              </div>
                                                              </b-form-group>
                                                          </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                            </b-card>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
            <div class="application-form-wrapper">
                <div class="application-itmes">
                    <add-products :products="products" />
                </div>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { ditfTabThreeStoreApi } from '../../../api/routes'
import AddProducts from './AddProducts'

export default {
    props: ['app_id', 'business_ids', 'products'],
    components: { AddProducts },
    data () {
        return {
            errors: [],
            valid: null,
            unitLoad: false,
            data: '',
            product: {
              prod_name_en: '',
              prod_name_bn: ''
            },
            type_ids: []
        }
    },
    created () {
      this.type_ids = [...this.business_ids]
    },
    watch: {
        currentLocale: function (newVal, oldVal) {
        }
    },
    computed: {
        businessTypeList () {
          return this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.businessTypeList.filter(el => el.status === 1)
        },
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                if (!this.products.length) {
                    return this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: 'Add at least one product item!',
                        color: 'red'
                    })
                }
                this.unitLoad = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }

                const myObj = {
                    app_id: this.app_id,
                    type_ids: this.type_ids,
                    products: this.products
                }

                const result = await RestApi.postData(internationalTradeFairServiceBaseUrl, ditfTabThreeStoreApi, myObj)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                this.unitLoad = false
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: result.message,
                        color: '#ee5253'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            }
        }
    }
}
</script>
